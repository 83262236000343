<template>
  <div class="profit-curve">
    <el-card>
      <div slot="header" class="clearfix">
        <span>盈亏曲线</span>
        <date-range style="float: right" v-on:change="getData(true)"></date-range>
      </div>
      <h-chart ref="myChart" id="chart" :options="options"></h-chart>
      <VarietyPicker v-show="options.series[0]&&options.series[0].data.length>0" class="custom-legend" :combine="true" v-on:varietyChange="getData(true)" />
      <div class="blackboard">
        <p class="star-level"><span>指标星级：</span>
          <el-rate v-model="starLevel" disabled></el-rate>
        </p>
        <p>指标释义：核算并展示单独交易品种或任意品种组合统计周期内独立盈亏时间序列。</p>
        <p>如何使用：点击下方图例对应品种，可选择展示或隐藏品种历史盈亏情况，便于统计、查看和跟踪单独品种盈亏随时间迁移变化。</p>
      </div>
    </el-card>
  </div>
</template>

<script>
import HChart from '@/components/HChart.vue'
import DateRange from '@/components/DateRange.vue'
import VarietyPicker from '@/components/VarietyPicker.vue'
import {Loading} from 'element-ui';
import {apiAnalysisData} from "../../api/analysis";
import {varietyFormatter} from "../../utils/tool";

export default {
  name: "SingleVariety",
  data() {
    return {
      starLevel: 5,
      options: {
        credits: {
          enabled: false
        },
        title: false,
        legend: {
          enabled: false
        },
        chart: {
          zoomType: 'x',
          resetZoomButton: {
            // relativeTo: 'chart',
            position: {
              // align: 'right', // by default
              // verticalAlign: 'top', // by default
              x: 0,
              y: -35
            }
          },
          spacingTop: 35,
          backgroundColor: null,
          plotBackgroundColor: null
        },
        colors: ['#EB3E41', '#229AFC', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80',
          '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa',
          '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050',
          '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'],
        xAxis: {
          // tickInterval: 10,
          categories: []
        },
        yAxis: {
          title: false,
        },
        series: [],
      }
    }
  },
  components: {
    HChart,
    DateRange,
    VarietyPicker
  },
  methods: {
    async getData () {
      if (this.$store.state.curAccountId.length === 0) {
        return false;
      }
      let loadingInstance = Loading.service({target: '.h-chart'});
      const params = {
        a_id: this.$store.state.curAccountId.join(','),
        type: this.$store.state.analysisType,
        b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date,
        v_code: this.$store.getters.checkedVarieties.join(',')
      }
      let res = await apiAnalysisData('varietyProfitCurve', params)
      if (res.data.code === 0 && res.data.data) {
        const resData = JSON.parse(JSON.stringify(res.data.data))
        this.options.xAxis.categories = resData.date
        let seriesData = []
        if (this.$store.state.isCombine) {
          let combineData = []
          const length = Object.keys(resData.f1).length
          const length1 = resData.date.length
          for (const key in resData.f1) {
            combineData.push(resData.f1[key])
          }
          for (let j = 0; j < length1; j++) {
            let num = 0;
            for (let i = 0; i < length; i++) {
              num += combineData[i][j]
            }
            seriesData.push(num)
          }
          this.options.series = [{name: '总盈亏',data: seriesData}]
        } else {
          for (const key in resData.f1) {
            seriesData.push({name: varietyFormatter(key),data: resData.f1[key]})
          }
          this.options.series = seriesData
        }
        this.$refs.myChart.redraw();
        loadingInstance.close();
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('setAllVarieties')
    this.getData();
  }
}
</script>

<style lang="scss">
  .profit-curve {
    .el-card {
      .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

      .chart {
        height: 540px;
        margin-bottom: 20px;
        background-image: url("../../assets/watermark.png");
        background-repeat: no-repeat;
        background-size: 269px 150px;
        background-position: center;
      }
    }

    .custom-legend {
      position: relative;
      top: -40px;
      margin: 20px 0;

      .el-button {
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 30px;
        padding: 0;
        border: none;
      }

      .btn {
        padding: 0 5px;
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 30px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        line-height: 30px;

        &.active {
          border: 1px solid #aa0000;
        }
      }

      .sec-btn {
        top: 40px;
      }

      .is-checked.active {
        .el-checkbox__inner {
          background-color: #AA0000;
          border-color: #AA0000;
        }

        .el-checkbox__label {
          color: #AA0000;
        }
      }

      .el-checkbox-group {
        text-align: start;
        padding: 0 40px 0 80px;
        line-height: 22px;
        font-size: 12px;

        .el-checkbox {
          width: 100px;
          margin-right: 0;
        }

      }

      .group-legend {
        text-align: left;
        height: 30px;
        margin-left: 80px;
        margin-bottom: 10px;
        border-bottom: 1px solid #999999;
      }
    }

  }
</style>
